<!--test.vue-->
<template>
  <div class="sl-textarea">
    <textarea class="sl-textarea-item" :placeholder="placeholder" v-model="tempValue"/>
  </div>
</template>

<script>
	export default {
		name: 'SlTextarea',
		props: {
			placeholder: {
				type: String,
				default: 'please enter'
			},
			value: {
				require: false
			}
		},
		data() {
			return {
				tempValue: ''
			}
		},
		created() {
			this.tempValue = this.value;
		},
		watch: {
			value(val) {
				this.tempValue = val;
			},
			tempValue() {
				this.$emit('input', this.temp)
			}
		}
	}
</script>

<style lang="scss" scoped>
  .sl-textarea-item {
    display: block;
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
  }
</style>
