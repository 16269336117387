<!--test.vue-->
<template>
	<div class="sl-image">
		<img :src="imageSrc" alt="">
	</div>
</template>

<script>
	export default {
		name: 'SlImage', // 这个名字很重要，它就是未来的标签名<sl-text></sl-text>
		props: {
			imageSrc: {
				type: String,
				default: 'https://cdn.senderlead.com/assets/img/default.png'
			}
		}
	}
</script>

<style lang="scss" scoped>
	.sl-image {
		width: 100%;
		height: 100%;
	}
	img {
		display: block;
		width: 100%;
		height: 100%;
	}
</style>
