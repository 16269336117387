<!--test.vue-->
<template>
  <div class="sl-image-carsousel">
    <!--异步加载轮播图的情况;-->
    <Swiper class="image-carsousel-swiper" v-if="imageSrcList.length" :autoPlay='true' :showIndicator='true' :interval="interval" :direction="direction" :duration="duration"><!--  > 0 -->
      <Slide class="image-carsousel-slide" v-for="(item,index) in imageSrcList" :key="index">
        <img  class="image-carsousel-image" :src="item" alt="">
      </Slide>
    </Swiper>
  </div>
</template>

<script>
	import { Swiper, Slide } from 'vue-swiper-component';
	export default {
		name: 'SlImageCarousel', // 这个名字很重要，它就是未来的标签名<sl-text></sl-text>
		components: {
			Swiper,
			Slide
		},
		props: {
			imageSrcList: {
				type: Array,
				default: () => {
					return [
						'https://cdn.senderlead.com/assets/img/default.png',
						'https://cdn.senderlead.com/assets/img/default.png',
						'https://cdn.senderlead.com/assets/img/default.png'
					]
				}
			},
			direction: {
				type: String,
				default: 'horizontal' // vertical
			},
			interval: {
				type: Number,
				default: 3000
			},
			duration: {
				type: Number,
				default: 800
			}
		},
		data() {
			return {

			}
		},
		methods: {

		}
	}
</script>

<style lang="scss" scoped>
.image-carsousel-swiper,
.image-carsousel-slide,
.image-carsousel-image{
  display: block;
  width: 100%;
  height: 100%;
}
</style>
<style>
  .image-carsousel-swiper .wh_swiper{
    height: 100%;
  }
</style>
