<!--test.vue-->
<template>
	<div class="sl-input">
		<input class="input-item" v-model="value" :placeholder="placeholder" type="text">
	</div>
</template>

<script>
	export default {
		name: 'SlInput',
		props: {
			placeholder: {
				type: String,
				default: 'please enter'
			},
			value: {
				require: false
			}
		},
		data(){
			return {
			}
		}
	}
</script>

<style lang="scss" scoped>
.input-item{
  display: block;
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
}
</style>
