/**
 * Component library entrance
**/

// Basic components
import Text from './text'
import Image from './image'
import ImageCarousel from './image-carousel'
import DividingLine from './dividing-line'
import RectangleBorder from './rectangle-border'
import Iframe from './iframe'
import Button from './button'

// Form component
import Input from './input'
import Textarea from './textarea'

// Functional components
import bgMusic from './bg-music'

// Business component

// List of all components
const components = [
	// Basic components
	Text,
	Image,
	ImageCarousel,
	RectangleBorder,
	DividingLine,
	Iframe,
	// Form component
	Button,
	Input,
	Textarea,
	// Functional components
	bgMusic,
	// Business component
]
// Define the install method, receiving Vue as a parameter
const install = function (Vue) {
	// Judge whether to install, after installation, do not continue to execute
	if (install.installed) return
	install.installed = true
	// Traverse all components registered
	components.map(component => Vue.component(component.name, component))
}

// It is executed only when Vue is detected. After all, we are based on Vue
if (typeof window !== 'undefined' && window.Vue) {
	install(window.Vue)
}

let _sl_register_components_object = {};
components.forEach(item => {
	_sl_register_components_object[item.name] = item
})

export {
	Text,
	Image,
	ImageCarousel,
	RectangleBorder,
	DividingLine,
	Iframe,
	Button,
	bgMusic,
	Input,
	Textarea,
	_sl_register_components_object
}

export default {
	install,
	// All components must have install in order to use Vue.use()
}
