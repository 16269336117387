<!--test.vue-->
<template>
  <div class="sl-text">
    {{text}}
  </div>
</template>

<script>
	export default {
		name: 'SlText', // 这个名字很重要，它就是未来的标签名<sl-text></sl-text>
		props: {
			text: {
				type: String,
				default: 'This is a text'
			}
		},
		data() {
			return {
				defaultStyle: {
					height: 40
				}
			}
		}
	}
</script>

<style lang="scss" scoped>

</style>
